$cdnUrl: 'https://cdn.harisenin.com';
@import "../../node_modules/tailwindcss/base.css";
@import "../../node_modules/tailwindcss/components.css";
@import "../../node_modules/tailwindcss/utilities.css";
@import "animation";
@import "custom";
@import "plyr";

@layer base {
  :root {
    --plyr-color-main: #0dad8e;
  }

  h1 {
    font-weight: 600;
  }

  body {
    position: relative;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    color: theme("colors.green");
  }

  dl,
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 1rem;
    padding-inline-start: 20px;
    list-style: revert;
  }
}
