.tooltip-container {
  padding-top: 10px;
  z-index: 1000 !important;
}

.slider-row {
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-align: center;

  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.price-box {
  /* Primary 1 */

  background: #f6f7f7;
  /* up shadow */

  box-shadow: 0px -22px 40px rgba(0, 0, 0, 0.04), 0px -9.19107px 20px rgba(0, 0, 0, 0.035),
    0px -4.91399px 12px rgba(0, 0, 0, 0.03), 0px -2.75474px 8px rgba(0, 0, 0, 0.025),
    0px -1.46302px 5.32008px rgba(0, 0, 0, 0.02), 0px -0.608796px 2.21381px rgba(0, 0, 0, 0.01);
}

.radio-wrapper {
  margin-bottom: 10px;
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 2px solid #0dad8e;
    border-radius: 100%;
    background: transparent;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 10px;
    height: 10px;
    background: #0dad8e;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
}

.react-player {
  video {
    border-radius: 10px;
  }
}

.responsive-font {
  @media (max-width: 576px) {
    font-size: 14px !important;
  }
}

.phone_input {
  & > div {
    input {
      padding-left: 8px;
      border: none;
      font-size: 14px;

      @media (max-width: 576px) {
        font-size: 12px;
      }

      &:focus {
        border: none;
        outline: none;
      }
    }
  }
}

.custom-list {
  counter-increment: item;
  margin-bottom: 10px;
  padding-left: 50px;
  font-size: 12px;

  &:before {
    content: counter(item);
    background: #0dad8e;
    border-radius: 100%;
    margin-left: -30px;
    margin-right: 10px;
    color: white;
    width: 20px;
    height: 20px;
    font-size: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

.checkbox-wrapper {
  input {
    opacity: 0;
    display: none;

    &:checked + label:before {
      background: #0dad8e;
    }

    &:checked + label:after {
      content: "";
      position: absolute;
      left: 5px;
      top: 9px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white,
        4px -8px 0 white;
      transform: rotate(45deg);
    }

    &:disabled {
      & + label:before {
        background-color: #979797;
      }
    }
  }

  label {
    position: relative;
    cursor: pointer;
    padding-left: 30px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      background: white;
      border: 1px solid rgba(105, 105, 105, 0.3);
      border-radius: 4px;
    }
  }

  // Box hover
  // &:hover + label:before {
  //   background: rgba($color: $green, $alpha: 0.4);
  // }

  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }
}
