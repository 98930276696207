.plyr {
  video {
    max-width: 82vw;
    margin: auto;
    height: auto;
  }

  &:fullscreen video {
    max-width: 100vw;
  }

  &__control {
    &--overlaid {
      padding: 2.5% !important;

      svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }

  @media (max-width: 576px) {
    video {
      max-width: 100%;
    }
  }
}
